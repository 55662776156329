import React, { useState } from "react"
import { Form, Tab, Tabs } from "react-bootstrap"
import classNames from "classnames"
import ReservationReviewForm from "./ReservationReviewForm/ReservationReviewForm"
import LocationForm from "./LocationForm/LocationForm"
import ReservationForm from "./ReservationForm/ReservationForm"
import EasyAppointmentForm from "./EasyAppointmentForm/EasyAppointmentForm"

import {
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { useServiceContext } from "../service.context"
import { bookAppointment } from "../../../../api/bookings"
import { createStripePaymentIntentForReservation } from "../../../../api/stripePayments"
import { sendBikeReservationData } from "../../../../api/contactOnline"
import {
  STEP_RESERVATION_REVIEW,
  STEP_APPOINTMENT,
  STEP_LOCATION,
  STEP_RESERVE,
  TabTitle,
  TabTitleWithCheckedIcon,
} from "../service.utils"
import { navigate } from "gatsby"
import * as style from "./index.module.scss"

const ServiceTabs = ({ location }) => {
  const isEasyAppintmentEnabled = Boolean(Number(process.env.IS_EASY_APPOINTMENT_ENABLED))
  const isSimpleProduct = location?.state?.nodeType === 'SimpleProduct'
  let productDetails;
  if(isSimpleProduct) {
    productDetails = `Product reservation: Product id: ${location?.state?.databaseId}, Product name: ${location?.state?.name}`
  }else {
    productDetails = `Product reservation: Product id: ${location?.state?.productDatabaseId}, Product name: ${location?.state?.name}`
  }
  const [activeStep, setActiveStep] = useState(STEP_RESERVE)

  const stripe = useStripe()
  const elements = useElements()
  const [processing, setProcessing] = useState(false)

  const {
    state: { formModel, reservationSteps },
  } = useServiceContext()

  const handleActiveStep = key => setActiveStep(key)

  /**
   *
   * Step Handler
   */
  const StepContentHandler = ({ content }) => {
    switch (content) {
      case STEP_RESERVE:
        return (
          <ReservationForm changeTab={handleActiveStep} location={location} />
        )
      case STEP_LOCATION:
        return <LocationForm changeTab={handleActiveStep} />
      case STEP_APPOINTMENT:
        return <EasyAppointmentForm changeTab={handleActiveStep} />
      case STEP_RESERVATION_REVIEW:
        return (
          <ReservationReviewForm
            changeTab={handleActiveStep}
            location={location}
          />
        )
      default:
        return null
    }
  }

  /**
   *
   * Form Submission
   */
  const handleSubmit = e => {
    const form = e.currentTarget
    e.preventDefault()

    const cardElement = elements.getElement(CardElement)
    e.stopPropagation()
    let postCode = form.querySelector('input[name="postcode"]').value
    let city = form.querySelector('input[name="city"]').value
    let addressLine1 = form.querySelector('input[name="addressLine1"]').value
    let addressLine2 = form.querySelector('input[name="addressLine2"]').value
    let productData = location.state
    let selectedStoreDetails = formModel.store.address.split(",")
    let variantId = null

    if (productData.type === "VARIABLE") {
      variantId = productData.variantData.databaseId
    }
    // ======= testing ======
    let billingDetails = {
      name: form.cardholderName.value,
      email: formModel.email,
      address: {
        city: city,
        line1: addressLine1,
        line2: addressLine2,
        country: 'GB',
        postal_code: postCode,
      }
    }
    let payload = {
      productId:
        productData.genericProductData?.databaseId || productData.databaseId,
      variantId: variantId,
      receiptEmail: formModel.email,
      orderDetails: {
        billingAddress: {
          firstName: formModel.firstName,
          lastName: formModel.lastName,
          address1: addressLine1,
          address2: addressLine2,
          city: city,
          postcode: postCode,
          country: "GB",
          email: formModel.email,
          phone: formModel.phone,
        },
        shippingAddress: {
          firstName: formModel.store.name,
          lastName: "",
          address1: selectedStoreDetails[0],
          address2: selectedStoreDetails[1],
          city: selectedStoreDetails[2],
          postcode: selectedStoreDetails[3],
          country: "GB",
          email: "info@centralebikes.co.uk",
          phone: "0203 987 6699",
        },
        paymentMethod: "Card",
        paymentMethodTitle: "Credit / Debit card",
      },
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // if (checkoutError) {
    //   elements.getElement('card').focus();
    //   return;
    // }

    // if (cardComplete) {
    //   setProcessing(true);
    // }

    createStripePaymentIntentForReservation(payload)
      .then(response => {
        const secret = response.data.stripePaymentSecret
        const orderId = response.data.orderId
        const orderDate = response.data.orderDate

        return secret
      })
      .then(secret => {
        stripe
          .confirmCardPayment(secret, {
            payment_method: {
              card: cardElement,
              billing_details: billingDetails,
            },
          })
          .then(function(result) {
            if (result.error) {
              // Show error to your customer (e.g., insufficient funds)
              console.error(result.error.message)
              setProcessing(false)
              navigate("/paymentFailure", {
                state: { errorMessage: result.error.message },
              })
            } else {
              // The payment has been processed!
              if (result.paymentIntent.status === "succeeded") {
                navigate("/reservationSuccess", {
                state: {
                  firstName: formModel.firstName,
                  lastName: formModel.lastName,
                  appointMentDate: formModel.appointmentDate,
                  email: formModel.email,
                  orderDate: new Date().toISOString(),
                  service: formModel.service,
                  store: formModel.store,
                  product: location.state
                },
              })
              }
            }
          })
      })

    // TODO: Easy appointment form submission
    if (form.checkValidity() === true) {
      // Build object to be sent to Easy!Appointments service

      const startTime = new Date(formModel.appointmentDate)
      startTime.setHours(8)
      const endTime = new Date(formModel.appointmentDate)
      endTime.setHours(9)


      const data = {
        appointment: {
          start: startTime,
          end: endTime,
          location: formModel.store.name,
          serviceId: 1,
          // TODO: update serviceId
        },
        customer: {
          firstName: form.firstName.value,
          lastName: form.lastName.value,
          email: form.email.value,
          phone: form.phone.value,
          notes: "",
        },
      }
      const contactOnlinePayload = {
        firstName: data.customer.firstName,
        lastName: data.customer.lastName,
        email: data.customer.email,
        phone: data.customer.email,
        message: productDetails,
    }
      // send data to Easy!Appointments service
      if (isEasyAppintmentEnabled) {
        bookAppointment(data)
          .then(res => {})
          .catch(error => {
            console.error("Error:", error)
          })
      }else {
        sendBikeReservationData(contactOnlinePayload)
        .then(res => {})
        .catch(error => {
          console.error("Error:", error)
        })
      }
    } else {
      e.stopPropagation()
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Tabs
        mountOnEnter={true}
        className={`${style.tab} mt-4`}
        id="controlled-tab-example"
        defaultActiveKey="services"
        activeKey={activeStep}
        onSelect={k => setActiveStep(k)}>
        {reservationSteps?.map((item, i) => {
          let title = item.isComplete ? (
            <TabTitleWithCheckedIcon item={item} activeStep={activeStep} />
          ) : (
            <TabTitle item={item} activeStep={activeStep} />
          )

          return (
            <Tab
              key={i}
              eventKey={item.id}
              title={title}
              disabled={item.isDisabled}>
              <div className={tabContainerClass}>
                <StepContentHandler content={item.id} />
              </div>
            </Tab>
          )
        })}
      </Tabs>
    </Form>
  )
}

const tabContainerClass = classNames("py-5", "px-3", "bg-grey-150")

export default ServiceTabs
