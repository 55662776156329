// extracted by mini-css-extract-plugin
export var bgGrey100 = "ProductImageSliderV2-module--bg-grey-100--80abd";
export var bgGrey150 = "ProductImageSliderV2-module--bg-grey-150--4ae26";
export var bgGrey200 = "ProductImageSliderV2-module--bg-grey-200--7402b";
export var bgGrey300 = "ProductImageSliderV2-module--bg-grey-300--8cbf3";
export var bgGrey400 = "ProductImageSliderV2-module--bg-grey-400--d8d7f";
export var bgGrey500 = "ProductImageSliderV2-module--bg-grey-500--03369";
export var bgGrey600 = "ProductImageSliderV2-module--bg-grey-600--42f97";
export var bgGrey700 = "ProductImageSliderV2-module--bg-grey-700--804ec";
export var bgGrey800 = "ProductImageSliderV2-module--bg-grey-800--081e4";
export var bgGrey900 = "ProductImageSliderV2-module--bg-grey-900--53f5d";
export var expandArrowsButton = "ProductImageSliderV2-module--expandArrowsButton--5afc6";
export var galleryImages = "ProductImageSliderV2-module--galleryImages--06a71";
export var gallerySlider1 = "ProductImageSliderV2-module--gallery-slider-1--89b7a";
export var gallerySlider2 = "ProductImageSliderV2-module--gallery-slider-2--f1e03";
export var gallerySlider3 = "ProductImageSliderV2-module--gallery-slider-3--0771a";
export var gallerySlider4 = "ProductImageSliderV2-module--gallery-slider-4--4aa8a";
export var gallerySlider5 = "ProductImageSliderV2-module--gallery-slider-5--634c9";
export var gallerySliderContainer = "ProductImageSliderV2-module--gallerySliderContainer--31541";
export var gallerySpinner = "ProductImageSliderV2-module--gallery-spinner--43348";
export var imagesOuterContainer = "ProductImageSliderV2-module--imagesOuterContainer--e7486";
export var mainImage = "ProductImageSliderV2-module--mainImage--56c15";
export var mainImageVideo = "ProductImageSliderV2-module--mainImageVideo--b4b9a";
export var nextArrowSlider = "ProductImageSliderV2-module--nextArrowSlider--4309b";
export var playerWrapper = "ProductImageSliderV2-module--playerWrapper--1de24";
export var previousArrowSlider = "ProductImageSliderV2-module--previousArrowSlider--a8dfc";
export var reactPlayer = "ProductImageSliderV2-module--reactPlayer--97dc4";
export var textGrey100 = "ProductImageSliderV2-module--text-grey-100--683dd";
export var textGrey150 = "ProductImageSliderV2-module--text-grey-150--b16a7";
export var textGrey200 = "ProductImageSliderV2-module--text-grey-200--180dc";
export var textGrey300 = "ProductImageSliderV2-module--text-grey-300--db797";
export var textGrey400 = "ProductImageSliderV2-module--text-grey-400--8fa4b";
export var textGrey500 = "ProductImageSliderV2-module--text-grey-500--15dcf";
export var textGrey600 = "ProductImageSliderV2-module--text-grey-600--fc93a";
export var textGrey700 = "ProductImageSliderV2-module--text-grey-700--c5170";
export var textGrey800 = "ProductImageSliderV2-module--text-grey-800--95ce2";
export var textGrey900 = "ProductImageSliderV2-module--text-grey-900--ed027";
export var youtubeGalleryIcon = "ProductImageSliderV2-module--youtubeGalleryIcon--daa90";
export var youtubeGalleryImage = "ProductImageSliderV2-module--youtubeGalleryImage--aa4fb";
export var youtubeGalleryImageContainer = "ProductImageSliderV2-module--youtubeGalleryImageContainer--21cc3";
export var youtubeGalleryImageVideo = "ProductImageSliderV2-module--youtubeGalleryImageVideo--4688e";
export var youtubeIcon = "ProductImageSliderV2-module--youtubeIcon--7619e";