import { graphql } from "gatsby"
import React from "react"

import ServiceForm from "./ServiceForm"

export const fragment = graphql`
  fragment ServicesFragment on WpPage_Flexlayouts_FlexibleLayouts_Services{
    sectionHeading
    paragraph       
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFServices = ({  
  sectionHeading,
  paragraph,
  config,
  location
}) => {  
  return (
    <ServiceForm
      sectionHeading={sectionHeading}
      paragraph={paragraph}
      config={config}
      location={location}
    />
  )
}
