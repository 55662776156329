import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap"
import CogIcon from "../../../../../images/icon-services-icon-service.inline.svg"

import { useServiceContext } from "../../service.context"
import {
  STEP_RESERVE,
  STEP_LOCATION,
  STEP_APPOINTMENT,
} from "../../service.utils"

import {
  CardElement,
} from "@stripe/react-stripe-js"

import {
  CARD_OPTIONS,
} from "../../../../../utils/checkoutHelper"
import { cardInput } from "../../../../../pages/checkout.module.scss"
import { Field } from "../../../../UI/Forms/Field"



const PaymentForm = React.memo(({ dispatch }) => {

  const [addressLine, setAddressLine] = useState("")
  const [addressLine2, setAddressLine2] = useState("")
  const [postcode, setPostcode] = useState("")
  const [city, setCity] = useState("")
  const [isEnabled, setIsEnabled] = useState(false)
  const [isSubmitting,] = useState(false)
  const [cardComplete, setCardComplete] = useState(false)

  const [termsCheckboxConfirmation, setTermsCheckboxConfirmation] = useState(
    false
  )


  /**
   *
   * Enable Form Submission checker
   */
  useEffect(() => {
    setIsEnabled(
      addressLine.length > 0 &&
        postcode.length > 0 &&
        city.length > 0 &&
        cardComplete &&
        termsCheckboxConfirmation
    )
  }, [addressLine, postcode, city, termsCheckboxConfirmation, cardComplete])
   /**
   *
   * Card details validation
   */
  const handleCardDetailsChange = ev => {
    ev.complete ? setCardComplete(true) : setCardComplete(false);
  };
  return (
      <>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Address Line 1*</Form.Label>
                <Form.Control
                  type="text"
                  name="addressLine1"
                  id="addressLine1"
                  placeholder="E.g 68 Charlotte St"
                  className="rounded-0 p-3"
                  value={addressLine}
                  onChange={e => setAddressLine(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Address Line 2</Form.Label>
                <Form.Control
                  type="text"
                  name="addressLine2"
                  id="addressLine2"
                  placeholder="Bloomsbury"
                  className="rounded-0 p-3"
                  value={addressLine2}
                  onChange={e => setAddressLine2(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={10} lg={6}>
              <Form.Group>
                <Form.Label>Postcode*</Form.Label>
                <Form.Control
                  type="text"
                  name="postcode"
                  id="postcode"
                  placeholder="E.g E1 6AN"
                  className="rounded-0 p-3"
                  required
                  value={postcode}
                  onChange={e => setPostcode(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group controlId="city">
                <Form.Label>City*</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="E.g London"
                  className="rounded-0 p-3"
                  required
                  value={city}
                  onChange={e => setCity(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={12}>
              <Field
                label="Cardholder Name*"
                id="cardholderName"
                type="text"
                placeholder="E.g Arianne Whittaker"
                required
              />
            </Col>
            <Col sm={12}>
              <CardElement
                options={CARD_OPTIONS}
                id="cardNumber"
                className={cardInput}
                onChange={handleCardDetailsChange}
              />
            </Col>
          </Row>
        <Form.Check
          className="py-4"
          type="checkbox"
          id="confirmation"
          required
          label={
            <div>
              <span dangerouslySetInnerHTML={{ __html: process.env.TERMS_AND_CONDITIONS }} />
            </div>
          }
          onClick={() => setTermsCheckboxConfirmation(!termsCheckboxConfirmation)}
        />

        <div className={confirmationRowTotalClass}>
          <small className="font-weight-bold">PAY & RESERVE MY BIKE NOW</small>
          <h2 className="text-right">
            <small>{process.env.CURRENCY_SYMBOL}</small>
            100
          </h2>
        </div>

        <Button
          block
          variant="primary"
          type="submit"
          disabled={
            !isEnabled}>
          {isSubmitting ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <>
              <CogIcon />
              &nbsp;
              Reserve my bike
            </>
          )}
        </Button>
        </>
  )
})


function ReviewForm({ changeTab, location }) {
  const {
    state: { formModel },
    dispatch,
  } = useServiceContext()
  const product = location.state

  return (
    <>
      <h2 className="text-uppercase text-center">
        Review and confirm your booking
      </h2>

      <Row className="justify-content-center mt-5">
        <Col lg={6}>
          <Card className="border-0">
            <div className="px-3 pt-5 pt-sm-5 px-sm-5">
              <h3 className={h3HeadingClass}>Appointment</h3>

              <h4 className={h4HeadingClass}>DATE OF APPOINTMENT</h4>
              <p className="mb-0">{formModel.appointmentDate}</p>
              <Button
                className="pl-0 mb-4"
                variant="link"
                onClick={() => changeTab(STEP_APPOINTMENT)}>
                Change
              </Button>

              <h4 className={h4HeadingClass}>STORE OF APPOINTMENT</h4>
              <p className="mb-0">{formModel.store.name}</p>
              <Button
                className="pl-0"
                variant="link"
                onClick={() => changeTab(STEP_LOCATION)}>
                Change
              </Button>
            </div>
            <hr />
            <div className="px-3 pt-4 pb-3 px-sm-5">
              <h3 className={h3HeadingClass}>Your information</h3>
              <h4 className={h4HeadingClass}>Name</h4>
              <p>{formModel.firstName} {formModel.lastName}</p>
              <h4 className={h4HeadingClass}>E-mail</h4>
              <p>{formModel.email}</p>
              <h4 className={h4HeadingClass}>
                Phone number
              </h4>
              <p>{formModel.phone !== "" ? formModel.phone :  "N/A" } </p>

              <h3 className={h3HeadingClass}>Bike information</h3>
              <h4 className={h4HeadingClass}>Name</h4>
              {/* have to use anchor tag to open product in new tab */}
              <a href={product.slug} target="_blank" rel="noreferrer">
              <p>{product.name}</p>
              </a>
              <h4 className={h4HeadingClass}>Colour</h4>
              <p>{product.colour}</p>
              <div className={serviceRowTotalClass}></div>
              <Button
                className="pl-0"
                variant="link"
                onClick={() => changeTab(STEP_RESERVE)}>
                Change
              </Button>
            </div>
          </Card>
        </Col>

        <Col lg={6}>
          <Card className="p-3 pt-5 p-sm-5 border-0">
            <h3 className={h3HeadingClass}>Payment details</h3>

            <PaymentForm formModel={formModel} dispatch={dispatch} location={location} />
          </Card>
        </Col>
      </Row>
    </>
  )
}

const serviceRowTotalClass = classNames(
  "d-flex",
  "justify-content-between",
  "align-items-center",
  "mt-4"
)
const confirmationRowTotalClass = classNames(
  "d-flex",
  "justify-content-between",
  "align-items-center",
  "my-4"
)
const h4HeadingClass = classNames("text-uppercase", "h6", "text-grey-600")
const h3HeadingClass = classNames("text-uppercase", "h2", "mb-4")

export default ReviewForm
