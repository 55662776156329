import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import { ServiceProvider } from "./service.context"
import ServiceTabs from "./ServiceTabs/ServiceTabs"
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY)

const ServiceForm = ({ sectionHeading, paragraph, config, location }) => {
  // Todo: setup ->  const { padding } = config to <Section /> c.  
  return (
    <ServiceProvider>
      <Container fluid="xl">
        <Row className="justify-content-center">
          <Col className="text-center" md={8}>
            <h1 className="text-uppercase">
              {sectionHeading}
            </h1>
            <p>{paragraph}</p>
          </Col>
          <Col md={12}>
          <Elements stripe={stripePromise}>
            <ServiceTabs location={location} />
          </Elements>
          </Col>
          <Col md={12}></Col>
        </Row>
      </Container>
    </ServiceProvider>
  )
}

export default ServiceForm
