import React, { useState } from "react"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { useServiceContext } from "../../service.context"
import {
  RadioLabel,
  STEP_APPOINTMENT,
  STEP_LOCATION,
} from "../../service.utils"



export const BIKE_STORES = [
  {
    id: "store_1",
    name: "Central bikes - Shepherds Bush",
    address: "15 Shepherds Bush Rd, Hammersmith, London, W6 7LX",

  },
]

const LocationForm = ({ changeTab }) => {
  const {
    state: { reservationSteps, formModel },
    dispatch,
  } = useServiceContext()
  const [store, setStore] = useState(formModel.store)
  /*
        Radio button selection
    */
  const handleChange = e => {
    // checked: e.target.checked
    // value: e.target.value
    // id: e.target.id
    const target = e.target
    setStore({ name: target.value, address: target.dataset.address })
  }

  /*
    Update steps
  */
  const updateSteps = () => {
    let updatedSteps = reservationSteps

    updatedSteps.forEach(x => {
      if (x.id === STEP_LOCATION) {
        x.isComplete = true
      }
      if (x.id === STEP_APPOINTMENT) {
        x.isDisabled = false
      }
    })

    dispatch({ type: "UPDATE_STEPS", updatedSteps })
  }

  /*
    Handle form submission
  */
  const _handleSubmit = () => {
    // 1 update steps
    updateSteps()

    // 2 update formModel
    dispatch({ type: "UPDATE_STORE", store: store })

    // go to next step
    changeTab(STEP_APPOINTMENT)
  }

  return (
    <>
      <h2 className="text-uppercase text-center">
        Choose your preferred meeting point
      </h2>
      <Row className="justify-content-center mt-5">
        <Col lg={11}>
          <Card className="p-3 pt-5 p-sm-5 border-0">
            <Row className="justify-content-center">
              {BIKE_STORES.map((x, i) => (
                <Col key={i} lg={5} className="mb-4 mb-lg-0">
                  <Card
                    body
                    className={store.name === x.name ? "border-secondary" : ""}>
                    <Form.Check
                      type="radio"
                      label={<RadioLabel name={x.name} address={x.address} />}
                      name="store"
                      id={x.id}
                      checked={store.name === x.name}
                      value={x.name}
                      data-address={x.address}
                      onChange={e => handleChange(e)}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
            <Row className="justify-content-center mt-5">
              <Col xl={4} md={6}>
                <Button
                  variant="primary"
                  block
                  disabled={store.name === null}
                  onClick={_handleSubmit}>
                  Continue To Appointment
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default LocationForm
