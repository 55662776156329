import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { Col, Row } from "react-bootstrap"

import { useServiceContext } from "../../service.context"
import {
  convertDateToSelectedFormat,
  STEP_APPOINTMENT,
  STEP_RESERVATION_REVIEW,
  TODAY,
} from "../../service.utils"

import { useDeviceDetect } from "../../../../../utils/useDeviceDetect"

import MobileDatepicker from "./MobileDatePicker"
import DesktopDatepicker from "./DesktopDatepicker"

import { getProviders } from "../../../../../api/bookings"
 

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Loader } from "../../../../../components/UI/Loader"
import { Alert } from "react-bootstrap"
import { setCalendarDates } from "../../../../../utils/easyAppointmentsCalendarHelper"  


const EasyAppointmentForm = ({ changeTab }) => { 
  const isEasyAppintmentEnabled = Boolean(Number(process.env.IS_EASY_APPOINTMENT_ENABLED))

  const {
    state: { formModel, reservationSteps },
    dispatch,
  } = useServiceContext()
  
  const { isMobile } = useDeviceDetect() 
  const didMount = useRef(true)

  const [date, setDate] = useState( 
    formModel.appointmentDate === null 
      ? new Date(TODAY)
      : new Date(formModel.appointmentDate) 
  )

  const [exceptionDates, setExceptionDates] = useState({})
  const [exceptionDatesFormatted, setExceptionDatesFormatted] = useState()
  const [selectableDatesArray, setSelectableDatesArray] = useState([])

  const [workingPlan, setWorkingPlan] = useState({})
  const [exceptionDays, setExceptionDays] = useState([]) 

  const [isLoading, setIsLoading] = useState(true)
  const [isResolved, setIsResolved] = useState(true)// set it to false
  const [isRejected, setIsRejected] = useState(false)    
 
  useEffect(() => {
    if(isEasyAppintmentEnabled === false ) {
      setIsRejected(false);
      setIsLoading(false);
      setIsResolved(true)
      return
    }    
    getProviders()   
      .then(res => {
        res.forEach(provider => {
          if (
            provider.id === parseInt(process.env.EASY_APPOINTMENTS_TEST_RIDE_ID) 
          ) {
            setExceptionDates(provider.settings.workingPlanExceptions)
            setWorkingPlan(provider.settings.workingPlan)
          }
        })
      })
      .catch(() => {
        setIsRejected(true)
        setIsLoading(false)
      })
  }, [])

  /*
        easyappointments workplan and exception dates handler  (from Workplan Exceptions)
    */
  useEffect(() => {
    if (didMount.current) {
      didMount.current = false
    } else {
      setCalendarDates(
        exceptionDates,
        workingPlan,
        setSelectableDatesArray, 
        setExceptionDatesFormatted,
        setExceptionDays
      )

      // status
      setIsLoading(false)
      setIsResolved(true)
    }
  }, [exceptionDates, workingPlan])

  /*
        set selected date on page load if no date is saved in Context
    */
  useEffect(() => {
    if (selectableDatesArray.length > 0 && formModel.appointmentDate === null) {
      setDate(new Date(selectableDatesArray[0]))
    }
  }, [selectableDatesArray])

  /*
        Update steps
    */
  const updateSteps = () => {
    // TODO: determine which steps to follow 

    let updatedSteps = reservationSteps
     
    updatedSteps.forEach(x => {
      if (x.id === STEP_APPOINTMENT) {
        x.isComplete = true
      }
      if (x.id === STEP_RESERVATION_REVIEW) {
        x.isDisabled = false
      }
    })

    dispatch({ type: "UPDATE_STEPS", updatedSteps })
  }
  /*
        Handle form submission
    */
  const _handleSubmit = () => {
    
    // 1 update steps
    updateSteps()

    // 2 update formModel
    dispatch({
      type: "UPDATE_APPOINTMENT_DATE",
      appointmentDate: convertDateToSelectedFormat(date),
    })

    // go to next step
    changeTab(STEP_RESERVATION_REVIEW)
  }

  return (
    <>
      <h2 className="text-uppercase text-center">Schedule your appointment</h2>
      <p className="text-center"> 
        Please select a day during the week you would like to pop in store.
      </p>

      {isLoading && <Loader center={true} />}

      {isRejected && (
        <Alert variant="danger">
          Apologies but there has been an error. Please refresh the page and try
          again.
        </Alert>
      )}

      {isResolved && isMobile && (
        <MobileDatepicker
          submit={_handleSubmit} 
          dateState={[date, setDate]}
          exceptionDates={exceptionDatesFormatted}
          selectableDatesArray={selectableDatesArray}
        />
      )}   

      {isResolved && !isMobile && ( 
        <Row className="justify-content-center mt-5">
          <Col lg={8}>
            <div className="d-flex justify-content-center">
              <DesktopDatepicker
                submit={_handleSubmit}
                dateState={[date, setDate]}
                exceptionDates={exceptionDatesFormatted}  
                minDate={selectableDatesArray[0]}
                exceptionDays={exceptionDays}
              />  
            </div> 
          </Col>
        </Row>
      )}
    </>
  )
}


export default EasyAppointmentForm
