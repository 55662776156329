import React, { useRef, useState } from "react"
import classNames from "classnames"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import * as style from "./MobileDatepicker.module.scss"
import Slider from "react-slick"
import LeftArrow from "../../../../../images/arrow-icon-left.inline.svg"
import RightArrow from "../../../../../images/arrow-icon-right.inline.svg"

import {
  convertDateToSliderMonthFormat,
  convertDateToSelectedFormat,
} from "../../service.utils"

import { Button, Card, Col, Row } from "react-bootstrap"

function MobileDatepicker({ submit, dateState, selectableDatesArray }) {
  let sliderDateMonthFormat = { weekday: "short" }
  let sliderDateDayFormat = { day: "numeric" }
  let blankDateIndex = []
  let datesArrayWithBlanks = []

  const [date, setDate] = dateState
  const slider = useRef(null)
  const [sliderMonth, setSliderMonth] = useState(
    convertDateToSliderMonthFormat(date)
  )

  // create array of indexes where blank boxes should appear on the mobile datepicker
  // static array: DATES_ARRAY
  //
  selectableDatesArray.reduce(
    (accumulator, currentValue, currentIndex, array) => {
      // To set two dates to two variables
      const date1 = new Date(accumulator)
      const date2 = new Date(currentValue)

      // To calculate the time difference of two dates
      const difference_In_Time = date2.getTime() - date1.getTime()

      // To calculate the no. of days between two dates
      const difference_In_Days = difference_In_Time / (1000 * 3600 * 24)

      difference_In_Days > 1 && blankDateIndex.push(currentIndex - 1)

      return currentValue
    }
  )

  // create new array of dates with blanks
  // static array: DATES_ARRAY
  //
  selectableDatesArray.map((date, i) => {
    // push current date into array
    datesArrayWithBlanks.push(date)

    // If current index matches a value in blank array index & is not the last date, push a blank
    blankDateIndex.includes(i) &&
      i !== selectableDatesArray.length - 1 &&
      datesArrayWithBlanks.push(0)
    return null
  })

  // Mobile datepicker: Get Active slide index
  // default value: 0
  //
  const getActiveSlideIndex = datesArrayWithBlanks.findIndex((d, i) => {
    return d === new Date(date).getTime()
  })

  const [activeSlideIndex, setActiveSlideIndex] = useState(
    getActiveSlideIndex === -1 ? 0 : getActiveSlideIndex
  )

  // Mobile datepicker: Go to next month
  //
  const goToNextMonth = () => {
    const now = new Date(sliderMonth)
    const nextMonth = new Date(sliderMonth).setMonth(now.getMonth() + 1, 1)

    datesArrayWithBlanks.map((date, i) => {
      if (date !== 0) {
        if (new Date(date).getMonth() === new Date(nextMonth).getMonth()) {
          setSliderMonth(convertDateToSliderMonthFormat(new Date(nextMonth)))
          slider.current.slickGoTo(i)
        }
      }
      return null
    })
  }

  // Mobile datepicker: Go to previous month
  //
  const goToPreviousMonth = () => {
    // slider.current.slickPrev()
    const now = new Date(sliderMonth)
    const previousMonth = new Date(sliderMonth).setMonth(now.getMonth() - 1, 1)

    datesArrayWithBlanks.map((date, i) => {
      if (date !== 0) {
        if (new Date(date).getMonth() === new Date(previousMonth).getMonth()) {
          console.log(i)
          setSliderMonth(
            convertDateToSliderMonthFormat(new Date(previousMonth))
          )
          slider.current.slickGoTo(i)
        }
      }
      return null
    })
  }

  const sliderSettings = {
    className: "center",
    infinite: false,
    centerPadding: "60px",
    slidesToShow: 4,
    swipeToSlide: true,
    speed: 250,
    arrows: false,
    focusOnSelect: true,
    initialSlide: activeSlideIndex,
    afterChange: current => {
      // If the first visible slider date is different from the slider month, change the slider month
      //
      const currentSlide = datesArrayWithBlanks[current]
      if (currentSlide !== 0) {
        const currentSlideDate = new Date(currentSlide)
        const sliderMonthDate = new Date(sliderMonth)

        if (currentSlideDate.getMonth() !== sliderMonthDate.getMonth()) {
          setSliderMonth(
            convertDateToSliderMonthFormat(new Date(currentSlideDate))
          )
        }
      }
    },
  }

  /*
        Handle form submission
    */
  const _handleSliderClick = e => {
    const index = parseInt(e.target.dataset.id)
    const selectedDate = new Date(parseInt(e.target.dataset.date))
    const sliderMonthDate = new Date(sliderMonth)

    setDate(selectedDate)
    slider.current.slickGoTo(index)
    setActiveSlideIndex(index)

    // If the selected slider date is different from the slider month, change the slider month
    //
    if (selectedDate.getMonth() !== sliderMonthDate.getMonth()) {
      setSliderMonth(convertDateToSliderMonthFormat(new Date(selectedDate)))
    }
  }

  return (
    <>
      <div className={mobileDatepickerRowClass}>
        <Card className={style.mobileDatepickerCol}>
          <div className={mobileMonthSelectorClass}>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => goToPreviousMonth()}>
              <span aria-label="previous">
                <LeftArrow />
              </span>
            </Button>
            <h6 className={sliderMonthClass}>{sliderMonth}</h6>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => goToNextMonth()}>
              <span aria-label="next">
                <RightArrow />
              </span>
            </Button>
          </div>

          <Slider ref={slider} {...sliderSettings}>
            {datesArrayWithBlanks.map((date, i) => {
              return date === 0 ? (
                <span key={i} className={style.sliderBlank} />
              ) : (
                <a
                  className={`${style.sliderDate} ${
                    activeSlideIndex === i ? style.sliderHighight : ""
                  }`}
                  key={i}
                  data-id={i}
                  data-date={date}
                  onClick={e => _handleSliderClick(e)}>
                  <span data-id={i} data-date={date}>
                    {new Date(date).toLocaleString(
                      "en-GB",
                      sliderDateMonthFormat
                    )}
                  </span>
                  {new Date(date).toLocaleString("en-GB", sliderDateDayFormat)}
                </a>
              )
            })}
          </Slider>

          <p className="text-center my-4">
            {convertDateToSelectedFormat(date)}
          </p>

          {submit && (
            <Row className="justify-content-center px-3 pb-4">
              <Col md={12}>
                <Button variant="primary" block onClick={submit}>
                  Continue To Review
                </Button>
              </Col>
            </Row>
          )}
        </Card>
      </div>
    </>
  )
}

const mobileDatepickerRowClass = classNames(
  "d-flex",
  "justify-content-center",
  "mt-5"
)
const mobileMonthSelectorClass = classNames(
  "d-flex",
  "justify-content-between",
  "align-items-center",
  "mb-4",
  "px-3",
  "pt-4"
)
const sliderMonthClass = classNames(
  "mb-0",
  "text-uppercase",
  "font-weight-bold"
)

export default MobileDatepicker
