import React, { createContext, useContext, useReducer } from "react"
import { RESERVE_STEPS_ARRAY } from "./service.utils"

const initialState = {
  formModel: {
    service: {
      name: null,
      price: null,
    },
    serviceDetails: [],
    store: {
      name: null,
      address: null,
    },
    appointmentDate: null,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    acceptTerms: false,
    addressLine: "",
    addressLine2: "",
    city: "",
    postcode: "",
  },
  reservationSteps: RESERVE_STEPS_ARRAY,
}

const ServiceContext = createContext()

function serviceReducer(state, action) {
  switch (action.type) {
    case "UPDATE_STEPS":
      return { ...state, steps: action.updatedSteps }
    case "UPDATE_SERVICE":
      return {
        ...state,
        formModel: {
          ...state.formModel,
          service: {
            name: action.service.name,
            price: action.service.price,
          },
          serviceDetails: action.service.details,
        },
      }
    case "UPDATE_STORE":
      return {
        ...state,
        formModel: {
          ...state.formModel,
          store: action.store,
        },
      }
    case "UPDATE_APPOINTMENT_DATE":
      return {
        ...state,
        formModel: {
          ...state.formModel,
          appointmentDate: action.appointmentDate,
        },
      }

    case "UPDATE_INFO":
      return {
        ...state,
        formModel: {
          ...state.formModel,
          firstName: action.firstName,
          lastName: action.lastName,
          email: action.email,
          phone: action.phone,
        },
      }

    case "UPDATE_TERMS_CHECK":
      return {
        ...state,
        formModel: {
          ...state.formModel,
          acceptTerms: action.isTermsChecked,
        },
      }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const ServiceProvider = props => {
  const [state, dispatch] = useReducer(serviceReducer, initialState)

  return (
    <ServiceContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ServiceContext.Provider>
  )
}

export function useServiceContext() {
  const context = useContext(ServiceContext)

  if (context === undefined) {
    throw new Error(
      "ServiceContext must be used within a ServiceContextProvider"
    )
  }
  return context
}
