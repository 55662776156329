import React, { Fragment, useEffect, useRef, useState } from "react"
import { getSliderImages } from "../../utils/productsHelper"
import * as style from "./ProductImageSliderV2.module.scss"
import Slider from "react-slick"
import Zoom from "react-medium-image-zoom"
import { v4 as uuidv4 } from "uuid"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player/youtube"
import NextArrowIcon from "../../images/icons-arrow-right-link-black.inline.svg"
import PrevArrowIcon from "../../images/icons-arrow-left-link-back.inline.svg"
import LogoSpinnerImage from "../../images/spinner-360.gif"

const getVariableSliderImages = ({ product }) => {
  const {
    variantData: { featuredImage: variantFeaturedImage },
    genericProductData: {
      galleryImages: variantGallery,
      featuredImage: genericFeaturedImage,
    },
  } = product

  if (variantFeaturedImage && variantGallery) {
    return [variantFeaturedImage.node, ...variantGallery.nodes]
  }
  if (genericFeaturedImage && variantGallery) {
    return [genericFeaturedImage.node, ...variantGallery.nodes]
  }
  if (variantGallery) {
    return [...variantGallery.nodes]
  }
  if (variantFeaturedImage) {
    return [variantFeaturedImage.node]
  }
  if (genericFeaturedImage) {
    return [genericFeaturedImage.node]
  }
}

const ProductImageSliderV2 = ({ product }) => {
  let hasSpinBike
  let vin

  const {
    galleryImages,
    product_videos: { youtubeVideoId } = {},
    nodeType,
    spinBikeImages,
    sku,
  } = product

  let featuredImage = product.featuredImage

  if(!featuredImage) {
    featuredImage = product.genericProductData.featuredImage
  }

  let sliderImages

  if (nodeType === "SimpleProduct") {

    sliderImages = getSliderImages(featuredImage, galleryImages)
    if(product.hasOwnProperty('spinBikeImages')){

      const { productIdentification: simpleSpinIds } = spinBikeImages

      const simpleVin = simpleSpinIds?.reduce(
        (vin, id) => (id.sku === sku ? id.vin : vin),
        null
      )
      if (simpleVin) {
        hasSpinBike = true
        vin = simpleVin
      }
    }
  }

  if (nodeType === "VariableProduct") {
    if(product.genericProductData.hasOwnProperty('spinBikeImages')){
    sliderImages = getVariableSliderImages({ product })
    const {
      variantData: { sku: variantSku },
      genericProductData: {
        spinBikeImages: { productIdentification: variantSpinIds },
      },
    } = product
    const variantVin = variantSpinIds?.reduce(
      (vin, id) => (id.sku === variantSku ? id.vin : vin),
      null
    )

    if (variantVin) {
      hasSpinBike = true
      vin = variantVin
    }
  }
  }

  function removeDuplicatesByProperty(arrayOfObjectsToFilter, property) {
    if (!arrayOfObjectsToFilter) {
      console.error("Attempted to run the removeDuplicatesByProperty function but there are no array of objects to filter.")
      return []
    }

    return arrayOfObjectsToFilter.filter(
      (arrayObject, index, ogArray) =>
        index ===
        ogArray.findIndex(
          currentObject => currentObject[property] === arrayObject[property]
        )
    )
  }

  const addVideo = (data, youtubeId) => {
    if (data.length === 0) {
      return [
        {
          preview: `https://img.youtube.com/vi/${youtubeId}/default.jpg`,
          video: `https://www.youtube.com/embed/${youtubeId}`,
        },
      ]
    } else {
      const newData = []
      for (let i = 0; i < data.length; i++) {
        newData.push(data[i])
        if (i === 0) {
          newData.push({
            preview: `https://img.youtube.com/vi/${youtubeId}/default.jpg`,
            video: `https://www.youtube.com/embed/${youtubeId}`,
          })
        }
      }
      return newData
    }
  }

  const [gallery, setGallery] = useState()
  const [featured, setfeatured] = useState()
  const featuredSlider = useRef(null)
  const gallerySlider = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [sliderIndex, setSliderIndex] = useState(0)

  let mainSliderSettings
  let gallerySettings
  let filteredSliderImages
  let galleryClassName


  useEffect(() => {
    setGallery(featuredSlider.current)
    setfeatured(gallerySlider.current)
  }, [])
  if (!hasSpinBike) {
    let sliderData = removeDuplicatesByProperty(sliderImages, "id")
    filteredSliderImages = youtubeVideoId
      ? addVideo(sliderData, youtubeVideoId)
      : sliderData

    filteredSliderImages = [...filteredSliderImages, ...filteredSliderImages]

    const getSlidesToShow = (elementsNumber, totalItems) => {
      if (elementsNumber >= 5 && totalItems >= 5) {
        return 5
      }
      if (elementsNumber > totalItems) {
        return totalItems
      }

      return elementsNumber
    }

    galleryClassName = style.gallerySlider5
    switch (filteredSliderImages.length) {
      case 1:
        galleryClassName = style.gallerySlider1
        break
      case 2:
        galleryClassName = style.gallerySlider2
        break
      case 3:
        galleryClassName = style.gallerySlider3
        break
      case 4:
        galleryClassName = style.gallerySlider4
        break
      default:
        galleryClassName = style.gallerySlider5
    }

    mainSliderSettings = {
      asNavFor: featured,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            dots: false,
            prevArrow: <PrevArrowIcon />,
            nextArrow: <NextArrowIcon />,

          },
        },
      ],
      lazyLoad: "progressive",
      arrows: false,
      afterChange: index => {
        gallerySlider.current.slickGoTo(index)
      },
    }

    gallerySettings = {
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: getSlidesToShow(5, filteredSliderImages.length),
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: getSlidesToShow(4, filteredSliderImages.length),
          },
        },
      ],
      asNavFor: gallery,
      slidesToShow: getSlidesToShow(5, filteredSliderImages.length),
      swipeToSlide: true,
      focusOnSelect: true,
      infinite: true,
      prevArrow: <PrevArrowIcon />,
      nextArrow: <NextArrowIcon />,
    }
  }
  return (
    <>
      <div
        className={style.imagesOuterContainer}
        id="product-images-container"
        data-vin={vin ? vin : null}>
        {hasSpinBike && (
          <div className="text-center">
            <img src={LogoSpinnerImage} className={`m-auto ${style.gallerySpinner}`} alt={featuredImage?.node?.altText} />
          </div>
        )}
        {!hasSpinBike && (
          <>
            <Slider
              ref={featuredSlider}
              {...mainSliderSettings}
              beforeChange={() => setIsPlaying(false)}>
              {filteredSliderImages.length &&
                filteredSliderImages.map((item, index) => (
                  // TODO: fix zoom/slider img index bug
                  <Fragment key={uuidv4()}>
                    {item.localFile && process.env.SITE_ID.startsWith('CEB') ? (
                      <Zoom
                        key={uuidv4()}
                        overlayBgColorEnd="rgba(50,50,50,0.8)"
                        zoomMargin={50}>
                        <GatsbyImage
                          className={style.mainImage}
                          image={getImage(item.localFile)}
                          key={uuidv4()}
                          alt={item.altText || ''}
                          loading={index === 0 ? "eager" : "lazy"}
                        />
                      </Zoom>
                    ): (
                      <GatsbyImage
                        className={style.mainImage}
                        image={getImage(item.localFile)}
                        key={uuidv4()}
                        alt={item.altText || ''}
                        loading={index === 0 ? "eager" : "lazy"}
                      />
                    )}
                    {item.video && (
                      <div key={uuidv4()} className={style.mainImageVideo}>
                        <div className={style.playerWrapper}>
                          <ReactPlayer
                            className={style.reactPlayer}
                            url={item.video}
                            width="100%"
                            height="100%"
                            controls={true}
                            stopOnUnmount={true}
                            playing={isPlaying}
                            onStart={() => {
                              setIsPlaying(true)
                            }}
                            onPlay={() => {
                              setIsPlaying(true)
                            }}
                            onPause={() => {
                              setIsPlaying(false)
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Fragment>
                ))}
            </Slider>
            <div className={style.gallerySliderContainer}>
              {filteredSliderImages.length > 1 && (
                <Slider
                  {...gallerySettings}
                  ref={gallerySlider}
                  className={galleryClassName}>
                  {filteredSliderImages.map((item, index) => {
                    return (
                      <div key={uuidv4()}>
                        {item.localFile && (
                          <GatsbyImage
                            className={style.galleryImages}
                            image={getImage(item.localFile)}
                            alt={item.altText}
                            loading={index === 0 ? "eager" : "lazy"}
                          />
                        )}
                        {item.video && (
                          <div className={style.youtubeGalleryImage}>
                            <div className={style.youtubeGalleryImageContainer}>
                              <img
                                className={style.youtubeGalleryImageVideo}
                                src={item.preview}
                                key={uuidv4()}
                              />
                              <StaticImage
                                src="../../images/youtube_social_icon_red.png"
                                alt="YouYube icon"
                                placeholder="none"
                                className={style.youtubeGalleryIcon}
                                style={{ position: "absolute" }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </Slider>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ProductImageSliderV2
