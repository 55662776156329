import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { Button, Card, Col, Form, Row, Alert } from "react-bootstrap"
import { useServiceContext } from "../../service.context"
import {
  RESERVATION_DEPOSIT,
  STEP_LOCATION,
  STEP_RESERVE,
} from "../../service.utils"

import isEmail from "validator/lib/isEmail"
import ProductImageSliderV2 from "../../../../ProductImageSliderV2"
import OfferRectangleIcon from "../../../../../images/Rectangle.png"

import * as styles from "./ReservationForm.module.scss"

const InfoForm = ({ dispatch, formModel, reservationSteps, changeTab }) => {

  const {
    firstName: firstNameState,
    lastName: lastNameState,
    email: emailState,
    phone: phoneState,
  } = formModel

  const [firstName, setFirstName] = useState(firstNameState)
  const [lastName, setLastName] = useState(lastNameState)
  const [email, setEmail] = useState(emailState)
  const [phone, setPhone] = useState(phoneState)

  const [isEnabled, setIsEnabled] = useState(false)
  /**
   *
   * Enable Form Submission checker
   */
  useEffect(() => {
    setIsEnabled(firstName.length > 0 && lastName.length > 0 && isEmail(email) && phone.length > 0)
  }, [firstName, lastName, email, phone])

  const updateSteps = () => {
    let updatedSteps = reservationSteps

    updatedSteps.forEach(x => {
      if (x.id === STEP_RESERVE) {
        x.isComplete = true
      }
      if (x.id === STEP_LOCATION) {
        x.isDisabled = false
      }
    })

    dispatch({ type: "UPDATE_STEPS", updatedSteps })
  }

  const clickHandler = () => {
    // 1 update steps
    updateSteps()

    // 2 update formModel
    dispatch({ type: "UPDATE_INFO", firstName, lastName, email, phone })
    dispatch({
      type: "UPDATE_SERVICE",
      service: {
        name: RESERVATION_DEPOSIT.name,
        price: RESERVATION_DEPOSIT.price,
      },
    })

    // go to next step
    changeTab(STEP_LOCATION)
  }

  /**
   *
   * Dispatch form values to Context after delay
   * todo: Removed as its causing a weird ui glitch. Will replace with better solution later.
   */
  return (
    <>
      <Form.Group>
        <Form.Label>First Name*</Form.Label>
        <Form.Control
          type="text"
          name="firstName"
          required
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Please enter your first name
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Last Name*</Form.Label>
        <Form.Control
          type="text"
          name="lastName"
          required
          value={lastName}
          onChange={e => setLastName(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Please enter your last name
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Email*</Form.Label>
        <Form.Control
          type="email"
          placeholder="E.g arianne.whittaker@gmail.com"
          name="email"
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Please enter your email
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>
          Telephone*
        </Form.Label>
        <Form.Control
          type="tel"
          name="phone"
          required
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />
      </Form.Group>

      <div className={confirmationRowTotalClass}>
        <small className="font-weight-bold">TOTAL TO PAY ONLINE</small>
        <h2 className="text-right">
          <small>{process.env.CURRENCY_SYMBOL}</small>
          100
        </h2>
      </div>

      <Button
        block
        variant="primary"
        onClick={() => clickHandler()}
        type="submit"
        disabled={!isEnabled}>
          Next
      </Button>
    </>
  )
}

const ProductDetails = ({ location }) => {

  let product = location.state || {}

  const { name, colour, onSale, price, regularPrice, salePrice } = product

  return (
    <div className="position-relative">
      <div>
        <div className="d-flex justify-content-between">
          <p className={productNameClass}>
            {name}
            <br />
            <small className={h4HeadingClass}>{colour}</small>
          </p>
          <div className={productNameClass}>
            {price && (
              <>
                {onSale ? (
                  <small className={styles.salePrice}>
                    {salePrice}{" "}
                    {regularPrice || price}
                  </small>
                ) : (
                  <small className={"regularPrice"}>
                    {regularPrice || price}
                  </small>
                )}
                <div className={styles.offerTag}>
                  <img
                    src={OfferRectangleIcon}
                    width={12}
                    height={16}
                    alt="offer icon"
                  />
                  <span>Central Bikes Offer</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        {location.state?.name  && <ProductImageSliderV2 product={product} />}
      </div>
    </div>
  )
}

function ReservationForm({ changeTab, location }) {
  const {
    state: { formModel, reservationSteps },
    dispatch,
  } = useServiceContext()

  return (
    <>
      <Row className="justify-content-center mt-5">
        <>
          {location.state?.name ? (
            <>
              <Col lg={6}>
                <Card className="p-3 pt-5 p-sm-5 border-0 h-100">
                  <ProductDetails location={location} />
                </Card>
              </Col>
              <Col lg={6}>
                <Card className="p-3 pt-5 p-sm-5 border-0 h-100">
                  <h3 className={h3HeadingClass}>Your Information</h3>
                  <p>
                    Here you can safely pay a £100 deposit to reserve your bike
                  </p>
                  <InfoForm
                    formModel={formModel}
                    dispatch={dispatch}
                    changeTab={changeTab}
                    reservationSteps={reservationSteps}
                  />
                </Card>
              </Col>
            </>
          ) : (
            <Alert variant="danger" className="w-100 text-center">
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p className="w-100">
              Click here to return to <Alert.Link href="/">home page</Alert.Link>
              </p>
            </Alert>
          )}
        </>
      </Row>
    </>
  )
}

const confirmationRowTotalClass = classNames(
  "d-flex",
  "justify-content-between",
  "align-items-center",
  "my-4"
)
const h4HeadingClass = classNames("text-uppercase", "h6", "text-grey-600")
const h3HeadingClass = classNames("text-uppercase", "h2", "mb-4")
const productNameClass = classNames("text-uppercase", "h3", "p-2", "text-left")

export default ReservationForm
