import React from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { convertDateToSelectedFormat, MAX_DATE } from "../../service.utils"
import DatePicker from "react-datepicker"

import "../../../../../styles/react-datepicker.css"

/**
 *  exceptionDays -> non-working days of the week from the workplan
 *  exceptionDates -> non-working dates from the workplanExceptions
 */
function DesktopDatepicker({
  submit,
  dateState,
  exceptionDates,
  exceptionDays,
  minDate,
  calendarContainer,
  className,
}) {
  const [date, setDate] = dateState

  /**
   *  Filter out excluded days of the week from workingPlan
   */
  const filterHandler = date => {
    // return day in human string format
    const day = new Intl.DateTimeFormat("en-US", { weekday: "long" })
      .format(date)
      .toLowerCase()

    if (exceptionDays.length > 0) {
      return !exceptionDays.includes(day)
    } else {
      // return true if all days are workdays
      return true
    }
  }

  const cardClass = calendarContainer ? "" : "p5"

  return (
    <Card className={cardClass}>
      {
        // todo: replace with loader
        <DatePicker
          selected={date}
          onChange={d => setDate(d)}
          //includeDates={DATES_ARRAY}
          excludeDates={exceptionDates}
          maxDate={MAX_DATE}
          minDate={minDate}
          filterDate={filterHandler}
          calendarContainer={calendarContainer}
          cllassName={className}
          inline
        />
      }

      <p className="text-center my-4">{convertDateToSelectedFormat(date)}</p>

      {submit && (
        <Row className="justify-content-center">
          <Col md={10}>
            <Button variant="primary" block onClick={submit}>
              Continue To Review
            </Button>
          </Col>
        </Row>
      )}
    </Card>
  )
}

export default DesktopDatepicker
