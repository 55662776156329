import React from 'react';

import Checkmark from '../../../images/icon-cart-icon-check.inline.svg';

export const STEP_RESERVE = 'reserve';
export const STEP_LOCATION = 'location';
export const STEP_APPOINTMENT = 'appointment';
export const STEP_RESERVATION_REVIEW = 'review';

export const RESERVATION_DEPOSIT = { price: 100, name: 'Deposit'}

export const RESERVE_STEPS_ARRAY = [
  { id: STEP_RESERVE, order: 1, title: 'RESERVE', isDisabled: false, isComplete: false },
  { id: STEP_LOCATION, order: 2, title: 'LOCATION', isDisabled: true, isComplete: false },
  { id: STEP_APPOINTMENT, order: 3, title: 'APPOINTMENT', isDisabled: true, isComplete: false },
  { id: STEP_RESERVATION_REVIEW, order: 4, title: 'REVIEW', isDisabled: true, isComplete: false },
]


export const TODAY = new Date();
TODAY.setHours(0,0,0,0);

const today2 = new Date();
export const MAX_DATE = today2.setMonth( today2.getMonth() + 2 );

export const DATES_ARRAY = [
    new Date(new Date().setHours(0,0,0,0)).setDate(TODAY.getDate()),
    new Date(new Date().setHours(0,0,0,0)).setDate(TODAY.getDate() + 2),
    new Date(new Date().setHours(0,0,0,0)).setDate(TODAY.getDate() + 3),
    new Date(new Date().setHours(0,0,0,0)).setDate(TODAY.getDate() + 4),
    new Date(new Date().setHours(0,0,0,0)).setDate(TODAY.getDate() + 5),
    new Date(new Date().setHours(0,0,0,0)).setDate(TODAY.getDate() + 6),
    new Date(new Date().setHours(0,0,0,0)).setDate(TODAY.getDate() + 8),
    new Date(new Date().setHours(0,0,0,0)).setDate(TODAY.getDate() + 11),
    new Date(new Date().setHours(0,0,0,0)).setDate(TODAY.getDate() + 31),
    new Date(new Date().setHours(0,0,0,0)).setDate(TODAY.getDate() + 32),
    new Date(new Date().setHours(0,0,0,0)).setDate(TODAY.getDate() + 35),
    new Date(new Date().setHours(0,0,0,0)).setDate(TODAY.getDate() + 36),
    new Date(new Date().setHours(0,0,0,0)).setDate(TODAY.getDate() + 70),
];

export const SELECTED_DATE_FORMAT = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
export const SLIDER_MONTH_FORMAT = { year: 'numeric', month: 'long' };

export const convertDateToSelectedFormat = date => date.toLocaleString('en-GB', SELECTED_DATE_FORMAT);
export const convertDateToSliderMonthFormat = date => date.toLocaleString('en-GB', SLIDER_MONTH_FORMAT);

export const getDatesFromRange = (startDate, endDate, exceptionDays = [], format = "dateObject") => {

    const dayInterval = 1000 * 60 * 60 * 24; // 1 day
    const duration = endDate - startDate;
    const steps = duration / dayInterval;

    const arr = Array.from({ length: steps+1 }, (v,i) => {

        let currentDate = new Date( startDate.valueOf() + (dayInterval * i) );
        let currentDay = (new Intl.DateTimeFormat('en-US', { weekday: 'long'}).format(currentDate)).toLowerCase();

        if( !exceptionDays.includes(currentDay) ) {
            let formattedDate = format === "dateObject"
                ?
                currentDate
                :
                currentDate.getTime();

            // set Hours will fix daylight savings issue
            return new Date(formattedDate).setHours(0,0,0,0);

        } else {
            return null;
        }
    });

    return arr.filter(x => x !== null);
}

export const TabTitleWithCheckedIcon = ({ item, activeStep }) => {
    const { id, order, title } = item;
    const styles = id !== activeStep ? "d-sm-inline-block d-none" : "";

    return (
        <>
        {order} <span className={styles}> - {title}</span> <span className="ml-2" aria-label="This step is complete"><Checkmark /></span>
        </>
    )
};

export const TabTitle = ({ item, activeStep }) => {

    const { id, order, title } = item;
    const styles = id !== activeStep ? "d-sm-inline-block d-none" : "";

    return (
        <>
        {order} <span className={styles}> - {title}</span>
        </>
    )
};

export const RadioLabel = ({ name, address }) => (
    <>
        <span className="text-uppercase h3 mb-2">{name}</span><br />
        <span>{address}</span>
    </>
);

export const TermsLabel = () => (
    <span>
        I accept Central Bikes <a href="#">Terms &amp; Conditions</a>
    </span>
);
